<template>
    <VueFinalModal v-model="show"
                   :click-to-close="props.clickToClose"
                   :content-class="contentClass"
                   class="flex justify-center items-center"
                   overlay-transition="vfm-fade"
                   content-transition="vfm-fade"
                   @before-open="$emit('before-open', $event)"
                   @opened="$emit('opened')"
                   @before-close="$emit('before-close', $event)"
                   @closed="$emit('closed')">
        <div class="relative">
            <div v-if="topTab"
                 class="absolute -top-8 -left-8 py-2 px-6
                        max-w-xs text-ellipsis overflow-hidden whitespace-nowrap
                        bg-eco-green-secondary-darker text-white rounded-br-md rounded-tl-md">
                {{ topTab }}
            </div>
            <div v-if="props.showClose" class="absolute top-0 right-0 text-3xl leading-3">
                <button class="button-modal-x" type="button" @click="close">
                    <PublicIcon icon="close" width="12" height="12" class="fill-current" />
                </button>
            </div>

            <div class="p-6">
                <!-- where the content goes -->
                <slot :close="close"></slot>
            </div>
        </div>
    </VueFinalModal>
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';

const props = defineProps({
    contentClass: {
        type: String,
        default: 'user-modal-content',
    },
    showClose: {
        type: Boolean,
        default: true,
    },
    clickToClose: {
        type: Boolean,
        default: true,
    },
    topTab: {
        type: String,
        default: null,
    },
});

defineEmits(['before-open', 'opened', 'before-close', 'closed']);

const show = ref(false);

onMounted(() => {
    nextTick(() => {
        show.value = true;
    });
});
onBeforeUnmount(() => {
    show.value = false;
});

const close = () => {
    show.value = false;
};
</script>
